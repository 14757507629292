import { yupResolver } from "@hookform/resolvers/yup";
import AddIcon from "@mui/icons-material/Add";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import Avatar from "@mui/material/Avatar";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import { SelectProps } from "@mui/material/Select";
import { Theme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";
import StrategyConstant from "core/constants/StrategyConstant";
import { Platforms } from "core/models";
import RouterPrompt from "core/routes/components/RouterPrompt";
import { getIsDirty } from "core/utils/commonHandler";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import { snackbarHandler } from "core/utils/snackbarHandler";
import {
  ActionMatchStrategyToVariant,
  ActionStrategy,
  DirectActionStrategy,
  INewActionFormInput,
  INewActionFormValues,
} from "modules/Action/models";
import ActionService from "modules/Action/services";
import { checkAndConfirmUnenclosedPlaceholders } from "modules/Action/utils";
import ActionValidation from "modules/Action/validations";
import { DEFAULT_TIME_DELTA } from "modules/Campaign/constants";
import { CampaignQuery } from "modules/Campaign/models";
import React from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import Button from "ui-kit/atoms/Button";
import Select from "ui-kit/components/Select";
import ActionContent from "../ActionContent";
import PreviewButton from "../DraftEditor/components/PreviewButton";

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    [theme.breakpoints.up("md")]: {
      width: theme.app.constants.sequence.area,
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  header: {
    borderRadius: theme.spacing(1.5, 1.5, 0, 0),
    backgroundColor: theme.palette.primary.light,
  },
  btnAdd: {
    margin: theme.spacing(-0.5, 0),
    padding: 0,
    zIndex: 1,
    "&:hover": {
      color: theme.palette.secondary.dark,
    },
  },
  btnAddIcon: {
    fontSize: 26,
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
    "&:hover": {
      transform: "scale(1.15)",
    },
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
  },
  divider: {
    margin: theme.spacing(2, 0, 4.5),
  },
}));

interface CreateActionCardProps {
  handleClose: () => void;
  formProps: {
    sequence: number;
    order_nr: number;
  };
  count: number | undefined;
  index: number;
}

// If last or only action create it, otherwise insert
const getMutateFunction = (i: number, c: number | undefined) =>
  i === c ? ActionService.createAction : ActionService.insertAction;

const initialAction = {
  execution_strategy: "",
  strategy_data: {
    template: null,
  },
};

const CreateActionCard = ({
  index,
  handleClose,
  formProps,
  count,
}: CreateActionCardProps): React.ReactElement | null => {
  const classes = useStyles();
  const schema = ActionValidation.actionSchema();
  const {
    control,
    errors,
    handleSubmit,
    setError,
    watch,
    getValues,
    formState,
    reset,
  } = useForm<INewActionFormInput>({
    resolver: yupResolver(schema),
    defaultValues: initialAction,
  });

  const isDirty = getIsDirty(formState.dirtyFields);

  const mutateFunction = getMutateFunction(index, count);
  const queryClient = useQueryClient();
  const mutateCreateAction = useMutation(
    (data: INewActionFormValues) => mutateFunction(data),
    {
      onSuccess: () => {
        reset(getValues());

        queryClient.invalidateQueries(["actions", formProps.sequence]);
        queryClient.invalidateQueries(CampaignQuery.actions);

        snackbarHandler.success("Action added!");
        handleClose();
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response, setError);
      },
    }
  );

  const onSubmit = (data: INewActionFormInput) => {
    const template = data?.strategy_data?.template;

    if (template) {
      const shouldContinue = checkAndConfirmUnenclosedPlaceholders(template);
      if (!shouldContinue) {
        return;
      }
    }

    const newData = {
      ...formProps,
      ...data,
      strategy_data: {
        ...data.strategy_data,
        template: template || null, // Make sure to pass null. It fails with empty string
      },
      execution_strategy: data.execution_strategy as DirectActionStrategy,
      time_delta: DEFAULT_TIME_DELTA,
      platform: Platforms.linkedin,
    };

    mutateCreateAction.mutate(newData);
  };

  const strategyField = watch("execution_strategy");
  const isEditable = StrategyConstant.STRATEGY_EDITABLE.includes(
    strategyField as ActionStrategy
  );
  return (
    <>
      <Paper variant="outlined" className={classes.card}>
        <CardHeader
          className={classes.header}
          avatar={
            <Avatar
              color="secondary"
              aria-label="new-strategy-icon"
              className={classes.icon}
            >
              <AddIcon />
            </Avatar>
          }
          action={
            <>
              <PreviewButton isPreviewable={isEditable} />
              {count !== 0 ? (
                <IconButton
                  aria-label="settings"
                  onClick={handleClose}
                  size="large"
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              ) : null}
            </>
          }
          title="New step"
          subheader="LinkedIn action that automatically executes."
        />
        <Divider />
        <form autoComplete="off">
          <CardContent>
            <Select
              name="execution_strategy"
              error={errors?.execution_strategy}
              control={control}
              options={StrategyConstant.STRATEGY_OPTIONS}
              label="Select action"
              formatValue={(option: SelectProps["value"]): string => {
                const value =
                  ActionMatchStrategyToVariant[option as ActionStrategy];
                const {
                  label: { account_is_actor: label },
                } = StrategyConstant.STRATEGY_OBJ[value];
                return label;
              }}
            />
            <Divider variant="middle" className={classes.divider} />
            <>
              {!!strategyField && isEditable && (
                <>
                  <ActionContent
                    control={control}
                    errors={errors}
                    execution={strategyField as DirectActionStrategy}
                  />
                </>
              )}
              <Button
                type="button"
                variant="contained"
                color="primary"
                size="small"
                // Stop event propagation
                onClick={handleSubmit(onSubmit)}
                disabled={!isDirty}
              >
                Save step
              </Button>
            </>
          </CardContent>
        </form>
      </Paper>
      <RouterPrompt when={isDirty} />
    </>
  );
};

interface CreteActionProps {
  open: number | null;
  handleOpen: (index: number) => void;
  handleClose: () => void;
  index: number;
  count: number | undefined;
  sequenceId: number;
}

const CreateAction = ({
  open,
  index,
  count,
  sequenceId,
  handleOpen,
  handleClose,
}: CreteActionProps): React.ReactElement => {
  const classes = useStyles();

  return (
    <>
      {/* Open if no other action exists or clicked index equals open */}
      {count === 0 || open === index ? (
        <CreateActionCard
          count={count}
          index={index}
          handleClose={handleClose}
          formProps={{ order_nr: index + 1, sequence: sequenceId }}
        />
      ) : (
        <Tooltip title="Add step" arrow placement="right">
          <IconButton
            type="button"
            color="secondary"
            className={classes.btnAdd}
            onClick={() => handleOpen(index)}
            size="large"
          >
            <AddCircleOutlineIcon
              className={classes.btnAddIcon}
              color="secondary"
            />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
};

export default CreateAction;

import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { Tooltip } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import { Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import StrategyConstant from "core/constants/StrategyConstant";
import RouterPrompt from "core/routes/components/RouterPrompt";
import { getIsDirty } from "core/utils/commonHandler";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import { snackbarHandler } from "core/utils/snackbarHandler";
import {
  ActionMatchStrategyToVariant,
  IAction,
  IExistingActionFormInput,
  IExistingActionFormValues,
} from "modules/Action/models";
import ActionService from "modules/Action/services";
import { checkAndConfirmUnenclosedPlaceholders } from "modules/Action/utils";
import { CampaignQuery } from "modules/Campaign/models";
import React from "react";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import Button from "ui-kit/atoms/Button";
import ActionContent from "../ActionContent";
import ActionMenuList from "../ActionMenuList";
import PreviewButton from "../DraftEditor/components/PreviewButton";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      justifyContent: "space-between",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    paddingRight: theme.spacing(1),
  },
  card: {
    width: "100%",
    overflow: "visible",
    [theme.breakpoints.up("md")]: {
      width: theme.app.constants.sequence.area,
    },
  },
  header: {},
  icon: {},
  expandIcon: {
    visibility: "hidden",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  btnDrag: {
    marginLeft: theme.spacing(-3),
    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(-7.5),
      marginRight: theme.spacing(1.5),
    },
    "&:hover": {
      cursor: "move",
      backgroundColor: "transparent",
    },
  },
  subheader: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    [theme.breakpoints.down("md")]: {
      maxWidth: 170,
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: 400,
    },
  },
  previewBtn: {
    paddingLeft: theme.spacing(3.5),
    marginRight: theme.spacing(2),
  },
  content: {
    padding: theme.spacing(5),
    "&:last-child": {
      paddingBottom: theme.spacing(5),
    },
  },
  stepContent: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "flex-start",
  },
  stepLabel: {
    marginRight: theme.spacing(2),
  },
  stepInfo: {
    color: theme.app.palette.action.color,
    backgroundColor: theme.app.palette.action.selectedBackground,
    padding: theme.spacing(0, 1.5),
    fontSize: 12,
    fontWeight: 500,
    borderRadius: 5,
    lineHeight: "20px",
  },
}));

interface ActionListItemProps {
  action: IAction;
  sequenceId: number;
  draggableProps: DraggableProvidedDragHandleProps | undefined;
}

const ActionListItem = ({
  action,
  sequenceId,
  draggableProps,
}: ActionListItemProps): React.ReactElement => {
  const queryClient = useQueryClient();
  const classes = useStyles();
  const {
    control,
    errors,
    handleSubmit,
    setError,
    getValues,
    formState,
    reset,
  } = useForm<IAction>({
    defaultValues: action,
  });

  const isDirty = getIsDirty(formState.dirtyFields);

  const currentVariant =
    ActionMatchStrategyToVariant[action.execution_strategy];
  const currentAction = StrategyConstant.STRATEGY_OBJ[currentVariant];

  const mutateUpdateAction = useMutation(
    (data: IExistingActionFormValues) => ActionService.updateAction(data),
    {
      onSuccess: () => {
        reset(getValues());

        queryClient.invalidateQueries(["actions", sequenceId]);
        queryClient.invalidateQueries(CampaignQuery.actions);

        snackbarHandler.success("Action updated!");
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response, setError);
      },
    }
  );

  const onSubmit = (data: IExistingActionFormInput) => {
    const template = data?.strategy_data?.template;

    if (template) {
      const shouldContinue = checkAndConfirmUnenclosedPlaceholders(template);
      if (!shouldContinue) {
        return;
      }
    }

    const newData = {
      id: action.id,
      ...data,
      strategy_data: {
        ...data.strategy_data,
        template: template || null,
      },
    };
    mutateUpdateAction.mutate(newData);
  };

  const IconComponent = currentAction.icon;
  const stepLabel = `${action.order_nr}. step`;
  const isEditable = StrategyConstant.STRATEGY_EDITABLE.includes(
    currentAction.strategy
  );
  const isAfterConnectionRequest =
    StrategyConstant.STRATEGY_AFTER_CONNECTION.includes(currentAction.strategy);

  return (
    <div className={classes.root}>
      <IconButton
        size="small"
        aria-label="drag-action"
        onClick={(e) => e.stopPropagation()}
        className={classes.btnDrag}
        {...draggableProps}
      >
        <DragIndicatorIcon />
      </IconButton>
      <Paper variant="outlined" className={classes.card}>
        <CardHeader
          avatar={
            <Avatar
              sx={{ backgroundColor: currentAction.color }}
              aria-label="strategy-icon"
            >
              {IconComponent && <IconComponent />}
            </Avatar>
          }
          action={
            <>
              <PreviewButton isPreviewable={isEditable} />
              <ActionMenuList actionId={action.id} sequenceId={sequenceId} />
            </>
          }
          title={stepLabel}
          titleTypographyProps={{
            variant: "subtitle2",
          }}
          subheader={
            <div className={classes.stepContent}>
              <Typography className={classes.stepLabel} component="div">
                {currentAction.label.account_is_actor}
              </Typography>
              {isAfterConnectionRequest && (
                <Tooltip
                  title="You can only send direct message to your 1st-degree connections on LinkedIn."
                  arrow
                  placement="top"
                >
                  <Typography className={classes.stepInfo} component="span">
                    1st-degree connections
                  </Typography>
                </Tooltip>
              )}
            </div>
          }
          subheaderTypographyProps={{
            className: classes.subheader,
          }}
          className={classes.header}
        />
        {isEditable && (
          <>
            <Divider />
            <form autoComplete="off">
              <CardContent className={classes.content}>
                <ActionContent
                  errors={errors}
                  control={control}
                  execution={action.execution_strategy}
                />
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  size="small"
                  // Stop event propagation
                  onClick={handleSubmit(onSubmit)}
                  disabled={!isDirty}
                >
                  Save changes
                </Button>
              </CardContent>
            </form>
          </>
        )}
      </Paper>
      <RouterPrompt when={isDirty} />
    </div>
  );
};

export default ActionListItem;
